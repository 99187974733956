import styled from "styled-components";
import { Palette } from "../../theme/Palette";

export const InTextLink = styled.a`
    text-decoration: none;
    color: ${Palette.White100};
    transition: color 0.1s;

    &:hover {
        color: ${Palette.White80};
    }
`;
