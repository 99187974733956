import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    padding: 64px 0px;
    background-image: radial-gradient(${Palette.Accent10}, transparent 60%);
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 400px);
    grid-column-gap: 32px;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-row-gap: 40px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
`;

export const LeftContentContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 16px;
    justify-items: start;
    align-items: start;
`;

export const PreviewImage = styled.img`
    border-radius: 4px;
    border: 1px solid ${Palette.Neutral50};
    max-width: 100%;
`;
