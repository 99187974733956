import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import { Viewport } from "../../utilities/Constants/Viewport";
import BackgroundTile from "./pixel-space-tile.png";

export const SectionRoot = styled.div`
    display: flex;
    justify-content: center;
    padding: 160px 16px;
    text-align: center;
    background-image: linear-gradient(180deg, ${Palette.Neutral100} 10%, transparent 50%),
        radial-gradient(${Palette.Accent10}, transparent 40%), url(${BackgroundTile});
    background-position: 50% 100%;
    background-repeat: repeat;
    background-attachment: scroll, scroll, fixed;
`;

export const BorderContainer = styled.div`
    background-image: linear-gradient(135deg, ${Palette.Primary100} 10%, ${Palette.Accent100} 50%);
    padding: 8px;
    border-radius: 4px;
    max-width: 960px;
    margin: 0px auto;
`;

export const SectionContainer = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 32px;
    justify-items: center;
    background-color: ${Palette.Neutral100};
    padding: 64px;
    max-width: 960px;
    border-radius: 4px;

    @media screen and (max-width: ${Viewport.Small}px) {
        padding: 32px;
    }
`;
