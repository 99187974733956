import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";
import { Viewport } from "../../../../../utilities/Constants/Viewport";

export const Root = styled.div`
    background-color: ${Palette.White10};
    border-radius: 16px;
    margin: 144px 0px;
`;

export const Container = styled.div`
    position: relative;
    padding: 32px;
    display: grid;
    grid-template-columns: minmax(560px, 0.75fr) 1fr;
    overflow: hidden;
    align-items: center;
    min-height: 550px;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: grid;
        grid-template-rows: 1fr minmax(560px, 0.75fr);
        grid-template-columns: auto;
        min-height: auto;
    }

    @media screen and (max-width: ${Viewport.Small}px) {
        grid-template-rows: 1fr minmax(320px, 0.5fr);
    }
`;

export const ImageContainer = styled.div``;

export const Image = styled.img`
    position: absolute;
    max-width: 640px;
    top: 0%;
    left: -64px;

    @media screen and (max-width: ${Viewport.Large}px) {
        top: auto;
        bottom: -64px;
    }

    @media screen and (max-width: ${Viewport.Small}px) {
        max-width: 400px;
        bottom: -40px;
    }
`;

export const ContentContainer = styled.div`
    @media screen and (max-width: ${Viewport.Large}px) {
        padding: 0;
        grid-row-start: 1;
    }
`;

export const HeadingSpan = styled.span`
    color: ${Palette.Neutral50};
`;

export const FeatureContainer = styled.div`
    margin-top: 32px;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 32px;
`;

export const Feature = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 8px;
`;

export const IconContainer = styled.div`
    grid-row: span 2;
    font-size: 24px;
    color: ${Palette.White100};
`;

export const FeatureHeading = styled.h3`
    color: ${Palette.White100};
    font-size: 24px;
    font-weight: 700;
`;

export const FeatureDescription = styled.p`
    color: ${Palette.White50};
    font-size: 16px;
    font-weight: 400;
`;

export const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const TagStyledOverride = styled.a`
    display: inline-block;
    padding: 8px;
    padding-left: 10px;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 4px;
    background-color: ${Palette.Primary100};
    color: ${Palette.Neutral0};
    font-size: 12px;
    line-height: 120%;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.1s;
    justify-self: left;

    &:hover {
        background-color: ${Palette.Primary110};
    }
`;
