import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 144px 0px;
    background-image: radial-gradient(${Palette.Accent10}, transparent 40%);
`;

export const CollapsibleContainer = styled.div`
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 32px;
    display: grid;
    grid-row-gap: 16px;
`;
