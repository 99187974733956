import * as React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../Home.styles";
import { Root, GridContainer, Card, IconWrapper, CardHeading, CardDescription } from "./Prizes.styles";
import CertificateIcon from "./certificate.png";
import MoneyIcon from "./money.svg";
import JobIcon from "./new-job.png";

export const Prizes: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <SectionHeading>{t("landingPage.prizes.heading")}</SectionHeading>
            <GridContainer>
                <Card>
                    <IconWrapper src={MoneyIcon} alt={t("landingPage.prizes.cashHeading")} />
                    <CardHeading>{t("landingPage.prizes.cashHeading")}</CardHeading>
                    <CardDescription>{t("landingPage.prizes.cashDescription")}</CardDescription>
                </Card>
                <Card>
                    <IconWrapper src={JobIcon} alt={t("landingPage.prizes.jobsHeading")} />
                    <CardHeading>{t("landingPage.prizes.jobsHeading")}</CardHeading>
                    <CardDescription>{t("landingPage.prizes.jobsDescription")}</CardDescription>
                </Card>
                <Card>
                    <IconWrapper src={CertificateIcon} alt={t("landingPage.prizes.certHeading")} />
                    <CardHeading>{t("landingPage.prizes.certHeading")}</CardHeading>
                    <CardDescription>{t("landingPage.prizes.certDescription")}</CardDescription>
                </Card>
            </GridContainer>
        </Root>
    );
};
