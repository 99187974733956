import * as React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../Home.styles";
import { Root, LogoContainer, LogoImg } from "./Sponsors.styles";
import AntlerLogo from "./Antler_logo_white.png";
import CrewLogo from "./crew-logo-alt-white.png"
import GitLabLogo from "./1200px-GitLab_logo-white.png";
import DeputyLogo from "./deputy-logo-full-color-white.png";
import FinderLogo from "./finder-logo-RGB-white_400x95.png";
import ImmutableLogo from "./immutable_white.png";
import MongoLogo from "./MongoDB_Logo_FullColorWhite_RGB.png";
import SCWLogo from "./securewcodewarrior_white.png";
import GALogo from "./GA.png"

export const Sponsors: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <SectionHeading>{t("landingPage.previousSponsors")}</SectionHeading>
            <LogoContainer>
                <LogoImg src={GitLabLogo} loading="lazy" />
                <LogoImg src={DeputyLogo} loading="lazy" />
                <LogoImg src={MongoLogo} loading="lazy" />
                <LogoImg src={GALogo} loading="lazy" />
                <LogoImg src={AntlerLogo} loading="lazy" />
                <LogoImg src={CrewLogo} loading="lazy" />
                <LogoImg src={FinderLogo} loading="lazy" />
                <LogoImg src={ImmutableLogo} loading="lazy" />
                <LogoImg src={SCWLogo} loading="lazy" />

            </LogoContainer>
        </Root>
    );
};
