import { useEffect } from "react";

interface IProps {
    readonly attributes?: { [key: string]: string };
    readonly onError?: () => void;
    readonly onLoad?: () => void;
}

export const useScript = (url: string, props: IProps) => {
    useEffect(() => {
        const script = document.createElement("script");
        const { attributes, onLoad, onError } = props;

        script.addEventListener("load", function () {
            onLoad?.();
        });
        script.addEventListener("error", function (_e) {
            onError?.();
        });

        script.src = url;
        script.async = true;

        for (const [key, value] of Object.entries(attributes ?? {})) {
            script.setAttribute(key, value);
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};
