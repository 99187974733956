import * as React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../Home.styles";
import { Root, GridContainer, Card } from "./Testimonials.styles";

export const Testimonials: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <SectionHeading>{t("landingPage.testimonials")}</SectionHeading>
            <GridContainer>
                <Card>
                    "Some of the most fun I've had in a while. It's been insanely difficult to find hackathons of this quality and calibre"
                </Card>
                <Card>"We actually cheered when our bot was making wins and we dreamed of being in the finals."</Card>
                <Card>
                    "It's incredibly rare to see a challenge with the same level of production quality as what you had. It really set you
                    apart."
                </Card>
            </GridContainer>
        </Root>
    );
};
