import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 144px 0px;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 16px;
    padding-top: 32px;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-row-gap: 16px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 1fr);
        justify-self: center;
    }
`;

export const Card = styled.div`
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 16px;
    justify-items: center;
    justify-self: center;
    background-color: ${Palette.White10};
    padding: 36px;
    max-width: 480px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        top: -10px;
        transform: translate(3px, -3px);
    }
`;

export const IconWrapper = styled.img`
    display: inline-block;
    width: 48px;
    height: auto;
`;

export const CardHeading = styled.h2`
    font-weight: 700;
    font-size: 18px;
    color: ${Palette.White100};
`;

export const CardDescription = styled.p`
    font-size: 16px;
    line-height: 140%;
    color: ${Palette.White50};
`;
