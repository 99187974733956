//TODO: fix issue of component not rendering on first page load
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TwitchStream } from "../../../../TwitchStream/TwitchStream";
import { Root, StreamContainer } from "./Livestream.styles";
import { SectionHeading, SectionDescription } from "../Home.styles";

export const Livestream: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <SectionHeading>{t("landingPage.showcase")}</SectionHeading>
            <SectionDescription>{t("landingPage.showcaseDescription")}</SectionDescription>
            <StreamContainer>
                <TwitchStream video="v1591500132" time="0h8m24s" width="100%" height="600px" />
            </StreamContainer>
        </Root>
    );
};
