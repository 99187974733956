import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 144px 0px;
    background-image: radial-gradient(${Palette.Accent10}, transparent 60%);
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 16px;
    padding-top: 32px;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-row-gap: 16px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        justify-self: center;
    }
`;

export const Card = styled.div`
    display: inline-block;
    background-color: ${Palette.White10};
    color: ${Palette.White100};
    font-size: 18px;
    line-height: 140%;
    padding: 64px 32px;
    max-width: 480px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    justify-self: center;

    &:hover {
        top: -10px;
        transform: translate(3px, -3px);
    }
`;
