import styled from "styled-components";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../theme/Palette";
import { Font } from "../../../../utilities/Constants/Font";

export const MainContentWrapper = styled.div`
    max-width: 1500px;
    margin: 0px auto;
    padding: 0px 16px;
`;

export const Root = styled.div`
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
    background: ${Palette.Neutral100};
`;

export const SectionHeading = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    font-size: 64px;
    line-height: 120%;
    font-weight: 700;
    color: ${Palette.Neutral0};

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;

export const SectionDescription = styled.div`
    font-size: 16px;
    line-height: 140%;
    color: ${Palette.White50};
`;
