import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionHeading, SectionDescription } from "../Home.styles";
import { Root, GridContainer, Card, CardHeading, CardSubHeading, CardDescription, IconWrapper } from "./Schedule.styles";
import { InTextLink } from "../../../../InTextLink/InTextLink";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../../utilities/Constants";
import TeamIcon from "../../../../Icons/group_white.svg";
import TrophyIcon from "../../../../Icons/events_white.svg";
import FlagIcon from "../../../../Icons/flag_white.svg";
import SparkleIcon from "../../../../Icons/sparkle_white.svg";

export const Schedule: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <SectionHeading>{t("landingPage.schedule.heading")}</SectionHeading>
            <div>
                <SectionDescription>{t("landingPage.schedule.datesNote")}</SectionDescription>
                <SectionDescription>
                    <Trans key="landingPage.schedule.pleaseJoinDiscord">
                        Please join our{" "}
                        <TelemetryLink href={Constants.DiscordLink} styledOverride={InTextLink} target="_blank">
                            Discord
                        </TelemetryLink>{" "}
                        for the latest announcements.
                    </Trans>
                </SectionDescription>
            </div>
            <GridContainer>
                <Card>
                    <IconWrapper src={TeamIcon} alt="Register" />
                    <CardHeading>Register</CardHeading>
                    <CardSubHeading>Open Now</CardSubHeading>
                    <CardDescription>
                        Create your account, purchase your entry ticket, and join our Discord community to find a team.
                    </CardDescription>
                </Card>
                <Card>
                    <IconWrapper src={TrophyIcon} alt="Tournament" />
                    <CardHeading>Tournament</CardHeading>
                    <CardSubHeading>1 — 14 Sep 2022</CardSubHeading>
                    <CardDescription>Teams are provided with the game specifications and resources to build their AI.</CardDescription>
                </Card>
                <Card>
                    <IconWrapper src={FlagIcon} alt="Finale" />
                    <CardHeading>Finale</CardHeading>
                    <CardSubHeading>14 Sep 2022</CardSubHeading>
                    <CardDescription>
                        It all ends here! Top teams compete head-to-head in an exciting livestream finale for the grand prize.
                    </CardDescription>
                </Card>
                <Card>
                    <IconWrapper src={SparkleIcon} alt="Playground" />
                    <CardHeading>Playground</CardHeading>
                    <CardSubHeading>15 Sep Onwards</CardSubHeading>
                    <CardDescription>
                        The game remains open in a free-for-all format for participants to learn and try new strategies.
                    </CardDescription>
                </Card>
            </GridContainer>
        </Root>
    );
};
