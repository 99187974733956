import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";
import { Font } from "../../../../../utilities/Constants/Font";

export const Root = styled.div`
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 32px;
    text-align: center;
    padding: 144px 0px;
    background-image: radial-gradient(${Palette.Accent10}, transparent 60%);
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-row-gap: 16px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        justify-self: center;
    }
`;

export const Card = styled.div`
    padding: 36px;
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-row-gap: 16px;
    justify-items: center;
`;

export const CardHeading = styled.h2`
    font-family: ${Font.SpaceGrotesk};
    font-weight: 700;
    font-size: 18px;
    color: ${Palette.White100};
    text-transform: uppercase;
    letter-spacing: 6px;
`;

export const CardSubHeading = styled.h2`
    font-weight: 700;
    font-size: 14px;
    color: ${Palette.White100};
    text-transform: uppercase;
`;

export const CardDescription = styled.p`
    font-size: 14px;
    line-height: 140%;
    color: ${Palette.White50};
`;

export const IconWrapper = styled.img`
    display: inline-block;
    width: 48px;
    height: auto;
`;
