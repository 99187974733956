import * as React from "react";
import { SectionRoot, BorderContainer, SectionContainer } from "./CallToActionSection.styles";

export const CallToActionSection: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <SectionRoot>
            <BorderContainer>
                <SectionContainer>{children}</SectionContainer>
            </BorderContainer>
        </SectionRoot>
    );
};
