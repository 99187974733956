import styled from "styled-components";

export const Root = styled.div`
    display: grid;
    grid-template-rows: repeat(3, auto);
    width: 100%;
    grid-row-gap: 32px;
    text-align: center;
    padding: 144px 0px;
`;

export const StreamContainer = styled.div`
    width: 100%;
`;
