import * as React from "react";
import {
    Root,
    Container,
    Image,
    HeadingSpan,
    ImageContainer,
    ContentContainer,
    FeatureContainer,
    Feature,
    IconContainer,
    FeatureHeading,
    FeatureDescription,
    TagWrapper,
    TagStyledOverride,
} from "./ProductFeatures.styles";
import { SectionHeading } from "../Home.styles";
import { useTranslation } from "react-i18next";
import StarterPreview from "./starter-preview.png";
import { RoundCheckIcon } from "../../../../Icons/RoundCheckIcon";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../../utilities/Constants";

export const ProductFeatures: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Container>
                <ImageContainer>
                    <Image src={StarterPreview} alt={t("landingPage.exploreAnyStrategy")} />
                </ImageContainer>
                <ContentContainer>
                    <SectionHeading>
                        <HeadingSpan>{t("landingPage.exploreAnyStrategy")}</HeadingSpan>
                        <div>{t("landingPage.fromTreeSearch")}</div>
                    </SectionHeading>
                    <FeatureContainer>
                        <Feature>
                            <IconContainer>
                                <RoundCheckIcon />
                            </IconContainer>
                            <FeatureHeading>{t("landingPage.featureOne")}</FeatureHeading>
                            <FeatureDescription>{t("landingPage.featureOneDescription")}</FeatureDescription>
                        </Feature>
                        <Feature>
                            <IconContainer>
                                <RoundCheckIcon />
                            </IconContainer>
                            <FeatureHeading>{t("landingPage.featureTwo")}</FeatureHeading>
                            <FeatureDescription>{t("landingPage.featureTwoDescription")}</FeatureDescription>
                        </Feature>
                        <Feature>
                            <IconContainer>
                                <RoundCheckIcon />
                            </IconContainer>
                            <FeatureHeading>{t("landingPage.featureThree")}</FeatureHeading>
                            <div>
                                <FeatureDescription>{t("landingPage.featureThreeDescription")}</FeatureDescription>
                                <TagWrapper>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("python3")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("typescript")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("golang")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("cplusplus")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("rust")}
                                    </TelemetryLink>
                                    <TelemetryLink href={Constants.ContributeStarterKit} target="_blank" styledOverride={TagStyledOverride}>
                                        {t("bomberlandLandingPage.contribute")}
                                    </TelemetryLink>
                                </TagWrapper>
                            </div>
                        </Feature>
                    </FeatureContainer>
                </ContentContainer>
            </Container>
        </Root>
    );
};
