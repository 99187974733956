import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import { Viewport } from "../../utilities/Constants/Viewport";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${Palette.White100};
    text-align: center;
    max-width: 100%;
    padding-bottom: 128px;
`;

export const LeaderboardBody = styled.div`
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    justify-content: center;

    @media screen and (max-width: ${Viewport.Large}px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Card = styled.div`
    background-color: ${Palette.White10};
    border-radius: 4px;
    display: grid;
    grid-template-columns: min-content min-content auto;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    padding: 16px 32px;
    text-align: left;
    transition: all 0.2s ease-in-out;

    &:hover {
        top: -10px;
        transform: translate(3px, -3px);
    }
`;

export const Caption = styled.div`
    color: ${Palette.Neutral50};
`;

export const Heading = styled.h2`
    color: ${Palette.Neutral0};
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 16px;
`;

export const RankTile = styled.div`
    grid-row: span 2;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
`;

export const FlagTile = styled.div`
    grid-row: span 2;
    display: flex;
    align-items: center;
    vertical-align: middle;
`;

export const TeamNameTile = styled.div`
    font-weight: 700;
    font-size: 16px;
    width: 128px;
    overflow: hidden;
`;

export const TeamRatingTile = styled.div`
    color: ${Palette.Neutral50};
    font-size: 12px;
`;
