import { LeaderboardResponse } from "../../../../../hooks/api/useSubmissionTrueskillLeaderboard";

export const getTopRankedTeams = (data: LeaderboardResponse | undefined, maxTeams: number) => {
    const seenSet = new Set<string>();
    const filteredLeaderboard = (data ?? [])
        .filter((ranking) => {
            if (seenSet.has(ranking.name)) {
                return false;
            } else {
                seenSet.add(ranking.name);
                return true;
            }
        })
        .slice(0, maxTeams);

    return filteredLeaderboard;
};
