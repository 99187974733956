import * as React from "react";
import { useCallback, useRef } from "react";
import { useScript } from "../../hooks/api/useScript";
import { ITwitchProps, EMBED_URL } from "./TwitchStream";

export const TwitchComponent: React.FC<ITwitchProps> = ({
    id,
    width,
    height,
    video,
    time,
    muted = true,
    autoplay = false,
    layout = "video",
}) => {
    const embed = useRef(null);
    const onLoad = useCallback(() => {
        if (typeof window !== "undefined" && window.Twitch) {
            if (embed.current !== null) {
                return;
            }
            const twitchEmbed = new window.Twitch.Embed(id, {
                width,
                height,
                video,
                time,
                muted,
                autoplay,
                layout,
            });
            twitchEmbed.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {
                const player = twitchEmbed.getPlayer();
                player.play();
            });
            embed.current = twitchEmbed;
        }
    }, []);

    useScript(EMBED_URL, { onLoad });

    return (
        <React.Fragment>
            <div id={id}></div>
        </React.Fragment>
    );
};
