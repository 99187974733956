import * as React from "react";
import { useTranslation } from "react-i18next";
import { Headline, Container, LogoImg, JoinNowLink, Root, Description, HeadlineContainer } from "./AboveFold.styles";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../../utilities/Constants";
import CoderOneLogoWhite from "../../../../Images/logo-white.svg";
import { SponsorLogos } from "../../../../SponsorLogos/SponsorLogos";

export const AboveFold: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Container>
                <HeadlineContainer>
                    <LogoImg src={CoderOneLogoWhite} alt="Coder One logo" />
                    <Headline>{t("landingPage.headline")}</Headline>
                </HeadlineContainer>
                <Description>{t("landingPage.description")}</Description>
                <TelemetryLink href={Constants.BomberlandRepo} target="_blank" styledOverride={JoinNowLink}>
                    {t("viewOnGitHub")}
                </TelemetryLink>
                <SponsorLogos isDarkTheme={true} />
            </Container>
        </Root>
    );
};
