import * as React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../Home.styles";
import { Root, CollapsibleContainer } from "./FAQ.styles";
import { Collapsible } from "../../../../Collapsible/Collapsible";
import { InTextLink } from "../../../../InTextLink/InTextLink";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../../utilities/Constants";

export const FAQ: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <SectionHeading>{t("landingPage.faq")}</SectionHeading>
            <CollapsibleContainer>
                <Collapsible title="How much does it cost to enter?">
                    Creating your account and developing an AI player for the game is free. To compete against others and qualify for the
                    leaderboard and prizing, you will need to purchase a season membership pass ($10). If you are currently a university
                    student, you may be eligible for free entry.{" "}
                    <TelemetryLink href={Constants.StudentClubPage} target="_blank">
                        Check this page for more details
                    </TelemetryLink>
                    . Your membership pass includes unlimited submissions for the rest of the season (end of 2022).
                </Collapsible>
                <Collapsible title="How difficult will it be?">
                    The competition is intended to be fairly challenging at a university student level. For beginners in programming, we'll
                    provide you with a step-by-step tutorial to help you get started with building your first AI.
                </Collapsible>
                <Collapsible title="What will the game be?">
                    The game will be a twist on our original 2-player Bomberman-like game called Bomberland. The new season will feature
                    updated game rules and mechanics. These will be announced at the start of the competition. You can learn more about the
                    previous season of Bomberland{" "}
                    <TelemetryLink href={Constants.BomberlandRepo} target="_blank" styledOverride={InTextLink}>
                        here
                    </TelemetryLink>
                    .
                </Collapsible>
                <Collapsible title="Will the environment be open-source?">
                    Yes, the new game will be open-sourced and available at the start of the competition. We encourage community
                    contributions in the form of starter kits for additional languages, tooling, etc.{" "}
                    <TelemetryLink href={Constants.GithubContributions} target="_blank" styledOverride={InTextLink}>
                        View our contribution guidelines here
                    </TelemetryLink>
                    .
                </Collapsible>
                <Collapsible title="How will the prize pool be distributed?">
                    The prize pool will be distributed amongst the top 8 teams, with higher-ranking teams taking a larger cut.
                </Collapsible>
                <Collapsible title="What programming language can I use?">
                    We'll be providing starter templates for Python 3. However, any setup that runs in a Linux docker container will work.
                    The game communicates over websockets, so you'll be able to use any language as long as you are able to implement the
                    communication layer in your language of choice (this is fairly advanced).
                </Collapsible>
                <Collapsible title="Do I need to know machine learning?">
                    No, this challenge is all about your programming, problem solving, game theory, and analytical thinking skills. We've
                    found previous submissions to be a mix of algorithmic as well as ML-based approaches. But if you've already got some
                    skills in ML and think you can apply them in this challenge - go ahead!
                </Collapsible>
                <Collapsible title="Do I need a team?">
                    We generally use the word 'team' to mean any 1 - 5 participants entering the tournament together. You absolutely can
                    join the tournament on your own. All prizes are split between the 'team'. Once you join the community{" "}
                    <TelemetryLink href={Constants.DiscordLink} target="_blank" styledOverride={InTextLink}>
                        Discord
                    </TelemetryLink>{" "}
                    group, you can use the 'looking-for-team' channel to find other people who want to team up.
                </Collapsible>
            </CollapsibleContainer>
        </Root>
    );
};
