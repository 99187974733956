import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";
import BackgroundTile from "./pixel-space-tile.png";
import { Font } from "../../../../../utilities/Constants/Font";

export const Root = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -64px;
    padding-top: 156px;
    overflow: hidden;
    background-image: linear-gradient(0deg, ${Palette.Neutral100} 10%, transparent 30%),
        radial-gradient(${Palette.Accent10}, transparent 40%), url(${BackgroundTile});
    background-position: 50% 100%;
    background-repeat: repeat;
    background-attachment: scroll, scroll, fixed;
`;

export const Container = styled.div`
    max-width: 800px;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    text-align: center;
    align-items: start;
    justify-content: center;
    z-index: 1;
    padding: 128px 0px;

    @media screen and (max-width: ${Viewport.Small}px) {
        align-items: center;
        text-align: center;
        max-width: 90%;
    }
`;
export const LogoImg = styled.img`
    display: inline-block;
    margin: auto;
    max-width: 400px;
    height: auto;
`;

export const HeroContent = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    max-width: 800px;
    text-align: center;
    align-items: start;
    z-index: 1;

    @media screen and (max-width: ${Viewport.Small}px) {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`;

export const Headline = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    color: ${Palette.Neutral0};
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 6px;
    text-transform: uppercase;
`;

export const Description = styled.h2`
    color: ${Palette.Neutral0};
    font-size: 16px;
`;

export const Subtitle = styled.h2`
    color: ${Palette.Neutral0};
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
`;

export const JoinNowLink = styled.a`
    font-family: ${Font.SpaceGrotesk};
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-color: ${Palette.Primary100};
    border-radius: 4px;
    color: ${Palette.Neutral0};
    cursor: pointer;
    display: inline-block;
    margin-top: 0;
    padding: 12px 32px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 1.32;
    box-shadow: 0px 0px 8px ${Palette.Primary50};
    justify-self: center;

    &:hover {
        background-color: ${Palette.Primary110};
    }
`;

export const HeadlineContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
`;
