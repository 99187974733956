import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";
import { Font } from "../../../../../utilities/Constants/Font";
import { Viewport } from "../../../../../utilities/Constants/Viewport";

export const JoinNowLink = styled.a`
    font-family: ${Font.SpaceGrotesk};
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-color: ${Palette.Primary100};
    border-radius: 4px;
    color: ${Palette.Neutral0};
    cursor: pointer;
    display: inline-block;
    margin-top: 0;
    padding: 12px 32px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 1.32;
    box-shadow: 0px 0px 8px ${Palette.Primary50};

    &:hover {
        background-color: ${Palette.Primary110};
    }
`;

export const Heading = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    font-size: 48px;
    line-height: 120%;
    font-weight: 700;
    color: ${Palette.Neutral0};

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;
