import styled from "styled-components";
import { Viewport } from "../../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 144px 0px;
    background-image: radial-gradient(${Palette.Accent10}, transparent 60%);
`;

export const LogoContainer = styled.div`
    display: flex;
    padding-top: 64px;
    flex-wrap: wrap;
    max-width: 800px;
    gap: 32px;
    align-self: center;
`;

export const LogoImg = styled.img`
    margin: auto;
    width: 180px;
    max-width: 100%;
    height: auto;

    @media screen and (max-width: ${Viewport.Small}px) {
        max-width: 128px;
    }
`;
