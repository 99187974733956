import { EventCode } from "@coderone/library";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSubmissionTrueskillLeaderboard } from "../../hooks/api/useSubmissionTrueskillLeaderboard";
import { CoderOneRoute } from "../Pages/CoderOneRoute";
import { RouterLink } from "../RouterLink/RouterLink";
import { Spinner } from "../Spinner/Spinner";
import { CountryCode } from "../PageSwitch/pages/Account/CountryPicker/Country.types";
import { CountryFlagSVG } from "../PageSwitch/pages/Account/CountryPicker/CountryFlagSVG";
import { getTopRankedTeams } from "../PageSwitch/pages/Leaderboard/LeaderboardRankings/getTopRankedTeams";
import {
    Heading,
    Caption,
    FlagTile,
    LeaderboardBody,
    RankTile,
    Root,
    Card,
    TeamNameTile,
    TeamRatingTile,
} from "./LeaderboardPreview.styles";

const maxTeams = 3;

export const LeaderboardPreview: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { isLoading, data } = useSubmissionTrueskillLeaderboard(EventCode.BomberlandV4);
    const filteredLeaderboard = useMemo(() => getTopRankedTeams(data, maxTeams), [data, maxTeams]);

    if (isLoading) {
        return (
            <Root>
                <Spinner />
            </Root>
        );
    }

    return (
        <Root>
            <Heading>{t("landingPage.topTeams")}</Heading>
            <LeaderboardBody>
                {filteredLeaderboard.map((team, index) => {
                    const rank = index + 1;
                    return (
                        <Card>
                            <RankTile>{rank}</RankTile>
                            <FlagTile>
                                <CountryFlagSVG code={CountryCode[team.country]} width="48px" height="36px" />
                            </FlagTile>
                            <TeamNameTile>{team.name}</TeamNameTile>
                            <TeamRatingTile>
                                {team.mu.toFixed(2)} ± {team.sigma.toFixed(2)}
                            </TeamRatingTile>
                        </Card>
                    );
                })}
            </LeaderboardBody>
            <Caption>
                <RouterLink href={CoderOneRoute.Leaderboard}>{t("landingPage.fullRankings")} →</RouterLink>
            </Caption>
        </Root>
    );
};
