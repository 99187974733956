import * as React from "react";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { SEO } from "../../../SEO/SEO";
import { useTranslation } from "react-i18next";
import { Root, MainContentWrapper } from "./Home.styles";
import coderOnePreview from "../../../Images/coder-one.jpg";
import { ProductFeatures } from "./ProductFeatures/ProductFeatures";
import { AboveFold } from "./AboveFold/AboveFold";
import { JoinUs } from "./JoinUs/JoinUs";
import { Livestream } from "./Livestream/Livestream";
import { About } from "./About/About";
import { Prizes } from "./Prizes/Prizes";
import { Schedule } from "./Schedule/Schedule";
import { Sponsors } from "./Sponsors/Sponsors";
import { Testimonials } from "./Testimonials/Testimonials";
import { FAQ } from "./FAQ/FAQ";
import { LeaderboardPreview } from "../../../LeaderboardPreview/LeaderboardPreview";

const Home: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("socialPreviewDescription");
    const title = t("buildGameChangingAI");
    const socialImage = `https://${process.env.GATSBY_HOST}${coderOnePreview}`;
    return (
        <React.Fragment>
            <SEO title={title} description={description} socialImage={socialImage} />
            <NavigationHeader isDarkTheme={true} />
            <Root>
                <AboveFold />
                <LeaderboardPreview />
                <MainContentWrapper>
                    <About />
                    <Prizes />
                    <ProductFeatures />
                    <Livestream />
                    <Schedule />
                    <Sponsors />
                    <FAQ />
                    <Testimonials />
                </MainContentWrapper>
                <JoinUs />
            </Root>
            <Footer isDarkTheme={true} />
        </React.Fragment>
    );
};

export default Home;
