import * as React from "react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TwitchComponent } from "./TwitchComponent";

declare global {
    interface Window {
        readonly Twitch?: {
            readonly Embed: any;
        };
    }
}

export const EMBED_URL = "https://embed.twitch.tv/embed/v1.js";

interface IProps {
    readonly width: string;
    readonly height: string;
    readonly video: string;
    readonly time?: string;
    readonly muted?: boolean;
    readonly autoplay?: boolean;
    readonly layout?: "video" | "video-with-chat";
}

export interface ITwitchProps extends IProps {
    readonly id: string;
}

export const TwitchStream: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const [id, setId] = useState<string | null>(null);
    useEffect(() => {
        if (typeof window !== "undefined" && id === null) {
            setId(uuidv4());
        }
    }, [id, setId]);
    if (id === null) {
        return null;
    }
    return <TwitchComponent {...props} id={id} />;
};
