import * as React from "react";
import { useTranslation } from "react-i18next";
import { SectionDescription } from "../Home.styles";
import { JoinNowLink, Heading } from "./JoinUs.styles";
import { RouterLink } from "../../../../RouterLink/RouterLink";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { CallToActionSection } from "../../../../CallToActionSection/CallToActionSection";

export const JoinUs: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <CallToActionSection>
            <Heading>{t("landingPage.callToAction.heading")}</Heading>
            <SectionDescription>{t("landingPage.callToAction.description")}</SectionDescription>
            <RouterLink href={CoderOneRoute.Signup}>
                <JoinNowLink as="div">{t("button.signUp")}</JoinNowLink>
            </RouterLink>
        </CallToActionSection>
    );
};
