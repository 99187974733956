import * as React from "react";
import { useTranslation } from "react-i18next";
import { GitHubDefaultStyleOverride } from "./GitHubButton.styles";
import { TelemetryLink } from "../TelemetryLink/TelemetryLink";
import { Constants } from "../../utilities/Constants";

interface IProps {
    readonly label?: string;
    readonly href?: string | "";
}

export const GitHubButton: React.FC<React.PropsWithChildren<IProps>> = ({ label = "viewOnGitHub", href = Constants.GithubLink }) => {
    const [t] = useTranslation();
    const buttonMessage = t(label);

    return (
        <TelemetryLink href={href} target="_blank" styledOverride={GitHubDefaultStyleOverride}>
            {buttonMessage}
        </TelemetryLink>
    );
};
