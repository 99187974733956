import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import { Font } from "../../utilities/Constants/Font";
import gitHubLogo from "./github.svg";

export const GitHubDefaultStyleOverride = styled.a`
    font-family: ${Font.SpaceGrotesk};
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-image: url(${gitHubLogo});
    background-position: 5% 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border-radius: 4px;
    border: 1px solid ${Palette.Neutral0};
    box-sizing: border-box;
    color: ${Palette.Neutral0};
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 1.32;
    margin-top: 0;
    padding: 12px 32px;
    padding-left: 50px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;

    &:hover {
        background-color: ${Palette.White10};
    }
`;
