import * as React from "react";
import { useTranslation } from "react-i18next";
import { Root, Container, LeftContentContainer, PreviewImage } from "./About.styles";
import { SectionHeading, SectionDescription } from "../Home.styles";
import BomberlandPreview from "../../../../Images/bomberland-preview.gif";
import { GitHubButton } from "../../../../GitHubButton/GitHubButton";
import { Constants } from "../../../../../utilities/Constants";

export const About: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Container>
                <LeftContentContainer>
                    <SectionHeading>{t("landingPage.whatsCoderOne")}</SectionHeading>
                    <SectionDescription>
                        <p>
                            Coder One is an annual AI programming tournament for engineers, data scientists, researchers, students, and
                            hobbyist programmers.
                        </p>
                        <p>
                            The goal is to program an AI player to compete in a multiplayer game against other teams. Participants are given
                            tutorials and resources for getting started. Over a two week submission period, participants will apply creative
                            problem solving skills and algorithms to improve their AI players.
                        </p>
                        <p>
                            Top teams will compete head-to-head in an exciting livestream finale to decide this season's grand champion
                            winner.
                        </p>
                        <p>Each season features new game mechanics, prizes, and a leaderboard to compete for.</p>
                    </SectionDescription>
                    <GitHubButton label={t("landingPage.viewPreviousSeasonsOnGitHub")} href={Constants.BomberlandRepo} />
                </LeftContentContainer>
                <PreviewImage src={BomberlandPreview} alt="Animated preview of Bomberland game" loading="lazy" />
            </Container>
        </Root>
    );
};
